<template>
	<b-modal :no-close-on-backdrop="true" :hide-header-close="isLoading" ref="modal" :title="title" :size="modalSize" class="modal-primary" @hidden="clearModalData">
		<CRow>
			<CCol>
				<b-card header="Datoteke">
					<CRow>
						<b-form-file
							multiple
							v-model="tmpFiles"
							:placeholder="uploadPlaceholder"
							:file-name-formatter="placeholderText"
							drop-placeholder="Povleci datoteko..."
							browse-text="Brskaj"
							@input="uploadFiles"
							:accept="acceptedFormats"/>
					</CRow>
					<CRow>
						<b-table
							v-if="filesToUpload.length > 0"
							class="mb-1"
							ref="uploadFilesTable"
							thead-class="hidden_header"
							striped
							hover
							:fields="filesToUploadTable_props.fields"
							:items="filesToUpload">
								<template v-slot:cell(progress)="data">
									<div v-if="data.item.progress === 'error'">
										<b-badge variant="danger">Napaka pri nalaganju</b-badge>
									</div>
									<b-progress v-else class="mt-1" :value="data.item.progress" :max="100" show-progress></b-progress>
								</template>
								<template v-slot:cell(file)="data">
									{{ data.item.name }}
								</template>
						</b-table>
					</CRow>
					<CRow v-if="filesToSave.length > 0">
						<b-table
							class="mb-1"
							ref="filesToSaveTable"
							thead-class="hidden_header"
							striped
							hover
							:fields="filesToSaveTable_props.fields"
							:items="filesToSave">
							<template v-slot:cell(actions)="data" >
								<div class="float-right">
									<b-badge variant="success" class="mr-5">Uspešno naloženo</b-badge>
									<b-button title="Odstrani" variant="danger" @click.stop="removeFileToSave_click(data.item)" size="sm">
										<CIcon name="cil-X"/>
									</b-button>
								</div>
							</template>
							<template v-slot:cell(archive)="data" >
								<CInputCheckbox :custom="true" :checked.sync="data.item.archive" label="Arhiva" inline/>
							</template>
						</b-table>
					</CRow>
				</b-card>
			</CCol>
		</CRow>
		<CAlert v-show="message.text != null" :color="message.type">
			{{ message.text }}
		</CAlert>
		<template slot="modal-footer" slot-scope="{ cancel }">
			<b-container fluid class="p-0">
				<b-row>
					<b-col align-self="end" class="ma_pos">
						<b-button :disabled="isLoading" style="margin-right:5px;" variant="secondary" @click="cancel()">Prekliči</b-button>
						<b-button :disabled="okButton.disabled || okButtonDisabled" :title="okButton.title" variant="primary" @click.stop="onOkModal()">{{ okButton.label }}</b-button>
					</b-col>
				</b-row>
			</b-container>
		</template>
		<vue-loading :active.sync="isLoading" :is-full-page="false"/>
	</b-modal>
</template>

<script>
import axios from 'axios';
import settings from '@/settings.js';
import { FILE_REMOVE_UPLOADED } from "@/store/actions/files";
import { EBILL_ATTACHMENTS_ADD } from "@/store/actions/ebillsAttachments";
const PH_TEXT = 'Izberi ali povleci datoteko ...';

export default {
	name: 'upload-files-modal',
	components: {
	},
	props: ['ebill'],
	methods: {
		showModal: function (modalInput) {
			/*
				mode 1 => Create
				mode 2 => Edit
			*/
			this.mode = modalInput.mode;
			this.initModal(modalInput);
		},
		initModal: function (modalInput) {
			if (this.mode === this.mode_EBILL_ATTACHMENT1) {
				this.title = 'Dodajanje priloge k e-računu #' + modalInput.ebill.id;
				this.ebillId = modalInput.ebill.id;
				this.okButton.label = 'Dodaj';
			} else {
				this.mode = this.mode_UNKNOWN0;
				this.title = 'Neznan način';
				this.okButton.label = 'Zapri';
			}
			this.$refs.modal.show();
		},
		onOkModal: function () {
			if (this.mode === this.mode_EBILL_ATTACHMENT1) {
				this.startProgress();
				this.$store.dispatch(EBILL_ATTACHMENTS_ADD, { attachments: this.filesToSave, ebillId: this.ebillId })
					.then((responseData) => {
						this.stopProgress();
						if (responseData.unsuccessfulSavedFiles.length > 0) {
							let message = 'Neuspešno shranjene datoteke: ';
							this.filesToSave = [];
							for(let unsuccessfulSavedFile of responseData.unsuccessfulSavedFiles) {
								message += unsuccessfulSavedFile.orginal_filename;
								this.filesToSave.push(unsuccessfulSavedFile);
							}
							this.showAlert(message, this.ALERT_TYPE_ERROR);
						} else {
							this.$emit('emit_filesUploaded', responseData.ebill.id, responseData.unsuccessfulSavedFiles.length);
							this.filesToSave = [];
							this.$refs.modal.hide();
						}
					})
					.catch(error => {
						this.stopProgress();
						console.error(error);
						if(error.response && error.response.data && error.response.data.code) {
							if (this.mode === this.mode_EBILL_ATTACHMENT1) {
								this.showAlert('Napaka pri dodajanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
							} else if (this.mode === this.mode_EDIT2) {
								this.showAlert('Napaka pri urejanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
							} else {
								this.showAlert('Neznan način modala! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
							}
						} else {
							this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
						}
					});
			} else {
				this.showAlert('Neznan način.', this.ALERT_TYPE_ERROR);
			}
		},
		clearModalData: function () {
			this.isLoading = false;
			this.title = '';
			this.okButton = {
				label: 'Shrani',
				progress: false,
				disabled: false
			};
			this.showAlert();
			this.mode = 0;
			this.tmpFiles = null;
			this.filesToUpload = [];
			for(let fileToSave of this.filesToSave) {
				this.$store.dispatch(FILE_REMOVE_UPLOADED, { filepath: fileToSave.filepath })
					.then((responseData) => {
						this.stopProgress();
						this.filesToSave = this.filesToSave.filter(function(obj) {
							return obj.filepath !== responseData.filepath;
						});
					})
					.catch(error => {
						this.stopProgress();
						console.error(error);
						if(error.response && error.response.data && error.response.data.code) {
							this.showAlert('Napaka pri odstranjevanju datoteke! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else {
							this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
						}
					});
			}
			this.filesToSave = [];
		},
		startProgress: function () {
			this.isLoading = true;
			this.okButton.disabled = true;
			this.okButton.progress = true;
		},
		stopProgress: function () {
			this.isLoading = false;
			this.okButton.disabled = false;
			this.okButton.progress = false;
		},
		showAlert: function (message, type, seconds) {
			if (message && type && seconds) {
				this.message.type = type;
				this.message.text = message;
				setTimeout(() => {
					this.showAlert();
				}, seconds * 1000);
			} else if (message && type) {
				this.message.type = type;
				this.message.text = message;
			} else if (message) {
				this.message.type = 'primary';
				this.message.text = message;
			} else {
				this.message.type = null;
				this.message.text = null;
			}
		},
		placeholderText: function () {
			return PH_TEXT;
		},
		uploadFiles: function () {
			for (let file of this.tmpFiles) {
				if (file.size > 10485760) {
					this.showAlert('Datoteka ' + file.name + ' je prevelika!', this.ALERT_TYPE_ERROR);
				} else {
					this.filesToUpload.push(file);
					let response = this.uploadFile(this, file);
				}
			}
		},
		uploadFile: function (ctx, file) {
			let args = new FormData();
			let settings = {
				headers: {
					'content-type': 'multipart/form-data'
				},
				onUploadProgress: function(progressEvent) {
					let loadedPrecent = progressEvent.loaded / progressEvent.total * 100;
					let orderAttachment = ctx.filesToUpload.find(pril => pril.name === file.name);
					orderAttachment.progress = loadedPrecent;
					ctx.$refs.uploadFilesTable.refresh();
				},
			};
			args.append('file', file);
			return axios.post('/files/upload', args, settings)
				.then(response => {
					let file = {
						orginal_filename: response.data.orginal_filename,
						server_side_filename: response.data.server_side_filename,
						filepath: response.data.filepath,
						type: response.data.type,
						archive: false
					}
					this.filesToUpload = this.filesToUpload.filter(function(obj) {
						return obj.name !== file.orginal_filename;
					});
					this.filesToSave.push(file);
					return response.data;
				})
				.catch(error => {
					let orderAttachment = ctx.filesToUpload.find(pril => pril.name === file.name);
					orderAttachment.progress = 'error';
					ctx.$refs.uploadFilesTable.refresh();
					if (error) {
						console.log(error);
					}
				});
		},
		removeFileToSave_click: function (item) {
			console.log('removeFileToSave_click', item);
			this.startProgress();
			this.$store.dispatch(FILE_REMOVE_UPLOADED, { filepath: item.filepath })
				.then((responseData) => {
					this.stopProgress();
					this.filesToSave = this.filesToSave.filter(function(obj) {
						return obj.filepath !== responseData.filepath;
					});
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.showAlert('Napaka pri odstranjevanju datoteke! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		}
	},
	data: function () {
		return {
			settings,
			isLoading: false,
			title: '',
			okButton: {
				label: 'Shrani',
				progress: false,
				disabled: false,
				title: ''
			},
			message: {
				type: null,
				text: null
			},
			ALERT_TYPE_SUCCESS: 'success',
			ALERT_TYPE_ERROR: 'danger',
			mode_UNKNOWN0: 0,
			mode_EBILL_ATTACHMENT1: 1,
			mode: 0,
			tmpFiles: null,
			filesToUpload: [],
			filesToUploadTable_props: {
				fields: [
					{ key: 'file', label: '', sortable: false },
					{ key: 'progress', label: '', sortable: false, class: 'orderAttachmentsTdProgress'  }
				],
			},
			filesToSaveTable_props: {
				fields: [
					{ key: 'orginal_filename', label: '', sortable: false },
					{ key: 'actions', label: '', sortable: false, class: '' },
					{ key: 'archive', label: '', sortable: false, class: '' }
				],
			},
			filesToSave: [],
			ebillId: null
		}
	},
	watch: {
	},
	computed: {
		modalSize () {
			return 'md';
		},
		uploadPlaceholder () {
			return PH_TEXT;
		},
		acceptedFormats () {
			if (this.mode == this.mode_EBILL_ATTACHMENT1) {
				return '.pdf, .xml, .env';
			}
			return null;
		},
		okButtonDisabled () {
			if (this.mode === this.mode_EBILL_ATTACHMENT1 && this.ebillId < 1) {
				this.okButton.title = 'Neveljaven id e-računa';
				return true;
			}
			if (this.mode === this.mode_EBILL_ATTACHMENT1 && this.filesToSave.length < 1) {
				this.okButton.title = 'Dodana mora biti vsaj ena datoteka';
				return true;
			}
			this.okButton.title = '';
			return false;

		}
	},
	created () {
	}
}
</script>

<style>
.gdt_border {
	border: 1px solid #c2cfd6!important;
}
.orderAttachmentsTdAcitons {
	width: 100px;
	max-width: 100px;
}
.orderAttachmentsTdProgress {
	width: 150px;
	max-width: 150px;
}
</style>
<style scoped>
.ma_pos {
	text-align: end;
}
.dt_border {
	border: 1px solid #c2cfd6!important;
}
</style>