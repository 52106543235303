<template>
	<b-modal :no-close-on-backdrop="true" :hide-header-close="isLoading" ref="modal" :title="title" :size="modalSize" class="modal-primary" @hidden="clearModalData">
		<div>
			<CRow>
				<CCol>
					<CInput
						label="Podjetje"
						:value="ebill.client != null ? getClientSelectLabel(ebill.client) : null"
						disabled />
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CSelect
						class="pt-1"
						prepend="Način obveščanja:"
						:options="settings.notificationMethodOptions"
						:value.sync="notificationMethod"
						@change="change_notificationMethod"
						:disabled="emailSended || halcomSended"
						custom />
				</CCol>
			</CRow>
			<CCard title="Za vnos več prejemnikov vnesite e-poštne naslove ločene z vejico" v-if="notificationMethod == 'email' && !emailSended">
				<div 
					block
					class="shadow-none card-header">
					Prejemniki
				</div>
					<CCardBody class="m-1">
						<b-form-textarea rows="3" v-model="emailRecievers" @input="change_emailInput"></b-form-textarea>
					</CCardBody>
			</CCard>
			<CCard v-if="notificationMethod == 'email' && !emailSended">
				<div 
					block
					class="shadow-none card-header">
					<b-row>
					<b-col class="text-left mt-1">
					Mail
					</b-col>
					<b-col class="text-right">
						<b-button title="Prikaži vsebino e-pošte" variant="outline" @click.stop="showMailContentCard = !showMailContentCard" size="sm">
							<CIcon v-if="!showMailContentCard" name="cil-caret-bottom"/>
							<CIcon v-else name="cil-caret-top"/>
						</b-button>
					</b-col>
					</b-row>
				</div>
				<CCollapse :show="showMailContentCard">
					<CCardBody class="m-1"><b-form-group >
					<label>Zadeva</label>
					<b-input-group>
						<b-form-input v-model="emailSubject"></b-form-input>
					</b-input-group>
					<label>Vsebina</label>
					<b-input-group>
						<b-form-textarea :rows="10" v-model="emailContent"></b-form-textarea>
					</b-input-group>
				</b-form-group>
					</CCardBody>
				</CCollapse>
			</CCard>
			<b-card v-else-if="notificationMethod == 'email' && emailSended">
				<div slot="header">
					Poslan email
				</div>
				<div>
					<b>Prejemniki:</b>
					<div class="border p-1">
						<b-badge v-for="reciever in sendedEmailAddresses" :key="reciever.id" variant="success" class="mr-2">{{ reciever }}</b-badge>
						<b-badge v-for="reciever in invalidEmailAddresses" :key="reciever.id" variant="danger" title="Neveljaven e-poštni naslov" class="mr-2">{{ reciever }}</b-badge>
					</div>
					<b>Zadeva:</b>
					<div class="border p-1" v-html="emailSubject"/>
					<b>Vsebina:</b>
					<div class="border p-1" v-html="emailContent"/>
				</div>
			</b-card>
			<b-card v-if="emailSended || halcomSended">
				<div slot="header">
					Poslane priloge
				</div>
				<div>
					<li v-if="sendedEbillEnvolope"><b-link title="Prenesi datoteko" @click.stop="getFileFromServer(sendedEbillEnvolope)" > {{ sendedEbillEnvolope.orginal_filename }} </b-link></li>
					<li v-if="sendedEbillFile"><b-link title="Prenesi datoteko" @click.stop="getFileFromServer(sendedEbillFile)" > {{ sendedEbillFile.orginal_filename }} </b-link></li>
					<li v-for="ebillAttachment of sendedEbillAttachments" :key="ebillAttachment.id">
						<b-link title="Prenesi datoteko" @click.stop="getFileFromServer(ebillAttachment)" > {{ ebillAttachment.orginal_filename }} </b-link>
					</li>
					<li v-for="orderAttachment of sendedOrderAttachments" :key="orderAttachment.id">
						<b-link title="Prenesi datoteko" @click.stop="getFileFromServer(orderAttachment)" > {{ orderAttachment.orginal_filename }} </b-link>
					</li>
				</div>
			</b-card>
			<div v-else>
				<b-card>
					<div slot="header">
						Priloge
					</div>
					<div>
						<li v-if="ebill.envelope">
							<b-link title="Prenesi datoteko" @click.stop="getFileFromServer(ebill.envelope)" > {{ ebill.envelope.orginal_filename }} </b-link>
							<b-badge v-if="ebill.envelope.archive" title="Datoteka se ne bo poslala" variant="secondary">Arhiva</b-badge>
							<b-badge v-if="notificationMethod === 'halcom' && ebill.envelope.file_size > 1992294" title="Datoteka je prevelika, da bi se poslala prek halcom" variant="warning">{{ convertBytes(ebill.envelope.file_size) }}</b-badge>
						</li>
						<li v-if="ebill.ebillFile">
							<b-link title="Prenesi datoteko" @click.stop="getFileFromServer(ebill.ebillFile)" > {{ ebill.ebillFile.orginal_filename }} </b-link>
							<b-badge v-if="ebill.ebillFile.archive" title="Datoteka e-računa je obvezna" variant="danger">Arhiva</b-badge>
							<b-badge v-if="notificationMethod === 'halcom' && ebill.ebillFile.file_size > 1992294" title="Datoteka je prevelika, da bi se poslala prek halcom" variant="warning">{{ convertBytes(ebill.ebillFile.file_size) }}</b-badge>
						</li>
						<li v-for="ebillAttachment of ebill.ebillAttachments" :key="ebillAttachment.id">
							<span v-if="ebillAttachment.filepath != null">
								<b-link title="Prenesi datoteko" @click.stop="getFileFromServer(ebillAttachment)" > {{ ebillAttachment.orginal_filename }} </b-link>
								<b-badge v-if="ebillAttachment.archive" title="Datoteka se ne bo poslala" variant="secondary">Arhiva</b-badge>
								<b-badge v-if="notificationMethod === 'halcom' && ebillAttachment.file_size > 1992294" title="Datoteka je prevelika, da bi se poslala prek halcom" variant="warning">{{ convertBytes(ebillAttachment.file_size) }}</b-badge>
							</span>
							<span v-else title="Datoteka ne obstaja"> {{ ebillAttachment.orginal_filename }} <b-badge variant="danger" title="Datoteka ne obstaja">-</b-badge> </span>
						</li>
					</div>
				</b-card>
				<b-card v-if="ebill.order">
					<div slot="header">
						Priloge naročilnice
					</div>
					<div>
						<li v-for="orderAttachment of ebill.order.order_attachments" :key="orderAttachment.id">
							<b-link title="Prenesi datoteko" @click.stop="getFileFromServer(orderAttachment)" > {{ orderAttachment.orginal_filename }} </b-link>
							<b-badge v-if="orderAttachment.archive" title="Datoteka se ne bo poslala" variant="secondary">Arhiva</b-badge>
							<b-badge v-if="notificationMethod === 'halcom' && orderAttachment.file_size > 1992294" title="Datoteka je prevelika, da bi se poslala prek halcom" variant="warning">{{ convertBytes(orderAttachment.file_size) }}</b-badge>
						</li>
					</div>
				</b-card>
			</div>
		</div>
		<CAlert v-show="message.text != null" :color="message.type">
			{{ message.text }}
		</CAlert>
		<template slot="modal-footer" slot-scope="{ cancel }">
			<b-container fluid class="p-0">
				<b-row>
					<b-col align-self="end" class="ma_pos">
						<b-button :disabled="isLoading" style="margin-right:5px;" variant="secondary" @click="cancel()">{{ cancelButton.label }}</b-button>
						<b-button v-if="!emailSended && !halcomSended" :disabled="okButton.disabled" variant="primary" @click.stop="onOkModal()">{{ okButton.label }}</b-button>
					</b-col>
				</b-row>
			</b-container>
		</template>
		<vue-loading :active.sync="isLoading" :is-full-page="false"/>
	</b-modal>
</template>

<script>
import settings from '@/settings.js';
import { EMAIL_GET_TEMPLATE, EMAIL_SEND } from "@/store/actions/email";
import { EBILL_SEND_HALCOM } from "@/store/actions/ebills";
import { FILE_DOWNLOAD } from "@/store/actions/files";

export default {
	name: 'ebill-send-modal',
	components: {
	},
	props: {
	},
	methods: {
		showModal: function (modalInput) {
			/*
				mode 1 => Normal send
			*/
			console.log('ebill', modalInput.ebill);
			this.mode = modalInput.mode;
			this.initModal(modalInput.ebill);
		},
		initModal: function (ebill) {
			if (this.mode === this.mode_NORMAL1) {
				this.initEbill(ebill);
				this.title = 'Pošiljanje e-računa';
				this.okButton.label = 'Pošlji';
			} else {
				this.mode = this.mode_UNKNOWN0;
				this.title = 'Neznan način';
				this.okButton.label = 'Zapri';
			}
			this.$refs.modal.show();
		},
		initEbill: function (ebill) {
			this.ebill.id = ebill.id;
			this.ebill.envelope = ebill.envelope;
			this.ebill.ebillFile = ebill.ebill_file;
			this.ebill.client = ebill.client;
			this.ebill.ebillAttachments = ebill.ebill_attachment;
			this.ebill.order = ebill.order;
			this.notificationMethod = ebill.client.notification_method;
			if (this.notificationMethod === 'email') {
				this.emailRecievers = ebill.client.email;
				this.getTemplate();
			}
		},
		onOkModal: function () {
			if (this.notificationMethod === 'email') {
				this.startProgress();
				this.$store.dispatch(EMAIL_SEND, { emailType: 'ebillEmail', emailSubject: this.emailSubject, emailContent: this.emailContent, emailRecievers: this.emailRecievers, ebillId: this.ebill.id })
					.then((responseData) => {
						this.stopProgress();
						this.emailSubject = responseData.subject;
						this.emailContent = responseData.content;
						this.sendedEbillEnvolope = responseData.emailAttachments.envelope;
						this.sendedEbillFile = responseData.emailAttachments.ebillFile;
						this.sendedEbillAttachments = responseData.emailAttachments.ebillAttachments;
						this.sendedOrderAttachments = responseData.emailAttachments.orderAttachments;
						this.sendedEmailAddresses = responseData.sendedEmailAddresses;
						this.invalidEmailAddresses = responseData.invalidEmailAddresses;
						this.emailSended = true;
						this.cancelButton.label = 'Zapri';
						this.showAlert('E-pošta je bila poslana', this.ALERT_TYPE_SUCCESS);
						this.$emit('emit_ebillSend');
					})
					.catch(error => {
						this.stopProgress();
						console.log(error);
						if(error.response && error.response.data && error.response.data.code) {
							if (error.response.data.code === 705) {
								if (error.response.data.usermsg) {
									this.showAlert(error.response.data.usermsg, this.ALERT_TYPE_ERROR);
								} else {
									this.showAlert(error.response.data.message, this.ALERT_TYPE_ERROR);
								}
							} else if (error.response.data.code === 711) {
								if (error.response.data.usermsg) {
									this.showAlert(error.response.data.usermsg, this.ALERT_TYPE_ERROR);
								} else {
									this.showAlert(error.response.data.message, this.ALERT_TYPE_ERROR);
								}
							} else {
								this.showAlert('Napaka pri pošiljanju emaila! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
							}
						} else {
							this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
						}
					});
			} else if (this.notificationMethod === 'halcom') {
				this.startProgress();
				this.$store.dispatch(EBILL_SEND_HALCOM, { ebillId: this.ebill.id, attachments: null })
					.then((responseData) => {
						this.stopProgress();
						this.halcomSended = true;
						this.sendedEbillEnvolope = responseData.envelope;
						this.sendedEbillFile = responseData.ebillFile;
						this.sendedEbillAttachments = responseData.ebillAttachments;
						this.sendedOrderAttachments = responseData.orderAttachments;
						this.cancelButton.label = 'Zapri';
						this.showAlert('E-račun je bil poslan prek halcom!', this.ALERT_TYPE_SUCCESS);
						this.$emit('emit_ebillSend');
					})
					.catch(error => {
						this.stopProgress();
						console.log(error);
						if(error.response && error.response.data && error.response.data.code) {
							if (error.response.data.code === 705) {
								if (error.response.data.usermsg) {
									this.showAlert(error.response.data.usermsg, this.ALERT_TYPE_ERROR);
								} else {
									this.showAlert(error.response.data.message, this.ALERT_TYPE_ERROR);
								}
							} else if (error.response.data.code === 711) {
								if (error.response.data.usermsg) {
									this.showAlert(error.response.data.usermsg, this.ALERT_TYPE_ERROR);
								} else {
									this.showAlert(error.response.data.message, this.ALERT_TYPE_ERROR);
								}
							} else {
								this.showAlert('Napaka pri pošiljanju prek halcom! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
							}
						} else if (error.response && error.response.data && error.response.data.message && error.response.data.message.includes('File already exists') ) {
							this.showAlert('E-račun že obstaja v mapi halcom.', this.ALERT_TYPE_ERROR);
						} else {
							this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
						}
					});
			} else {
				this.showAlert('Neznan način obveščanja!', this.ALERT_TYPE_ERROR);
			}
			
		},
		clearModalData: function () {
			this.isLoading = false;
			this.title = '';
			this.okButton = {
				label: 'Shrani',
				progress: false,
				disabled: false
			};
			this.cancelButton = {
				label: 'Prekliči',
				progress: false,
				disabled: false
			};
			this.showAlert();
			this.mode = 0;
			this.emailSubject = null;
			this.emailContent = null;
			this.emailRecievers = null;
			this.notificationMethod = settings.notificationMethodOptions[0].value;
			this.sendedEbillEnvolope = null;
			this.sendedEbillFile = null;
			this.sendedEbillAttachments = [];
			this.sendedOrderAttachments = [];
			this.emailSended = false;
			this.halcomSended = false;
			this.showMailContentCard = false;
			this.sendedEmailAddresses = [];
			this.invalidEmailAddresses = [];
			this.resetEbillObject();
		},
		resetEbillObject: function () {
			this.ebill.id = null;
			this.ebill.orginal_filename = '';
			this.ebill.filepath = '';
			this.ebill.receiverName = '';
			this.ebill.ebillAttachments = [];
			this.ebill.order = null;
		},
		startProgress: function () {
			this.isLoading = true;
			this.okButton.disabled = true;
			this.okButton.progress = true;
		},
		stopProgress: function () {
			this.isLoading = false;
			this.okButton.disabled = false;
			this.okButton.progress = false;
		},
		showAlert: function (message, type, seconds) {
			if (message && type && seconds) {
				this.message.type = type;
				this.message.text = message;
				setTimeout(() => {
					this.showAlert();
				}, seconds * 1000);
			} else if (message && type) {
				this.message.type = type;
				this.message.text = message;
			} else if (message) {
				this.message.type = 'primary';
				this.message.text = message;
			} else {
				this.message.type = null;
				this.message.text = null;
			}
		},
		getTemplate: function () {
			this.startProgress();
			this.$store.dispatch(EMAIL_GET_TEMPLATE, { emailType: 'ebillEmail', emailRecievers: this.emailRecievers })
				.then((responseData) => {
					this.stopProgress();
					this.emailSubject = responseData.subject;
					this.emailContent = responseData.content;
				})
				.catch(error => {
					this.stopProgress();
					console.log(error);
				});
		},
		getFileFromServer: function (item, actionType) {
			this.startProgress();
			return this.$store.dispatch(FILE_DOWNLOAD, { filepath: item.filepath })
				.then((response) => {
					this.stopProgress();
					if (actionType === 'preview') {
						var newBlob = new Blob([response.data], {type: response.headers['content-type']});
						const data = window.URL.createObjectURL(newBlob);
						window.open(data, '_blank');
					} else {
						var newBlob = new Blob([response.data], {type: response.headers['content-type']});
						const data = window.URL.createObjectURL(newBlob);
						var link = document.createElement('a');
						link.href = data;
						link.download = item.orginal_filename;
						link.click();
						setTimeout(function () {
							// For Firefox it is necessary to delay revoking the ObjectURL
							window.URL.revokeObjectURL(data)
						}, 100);
					}
				})
				.catch(error => {
					this.stopProgress();
					console.log(error);
					this.$refs.toastr.e('Napaka! Prosimo poizkusite kasneje.');
				});
		},
		change_notificationMethod: function (item) {
			if (this.notificationMethod === 'email') {
				this.getTemplate();
			}
		},
		getClientSelectLabel: function(client) {
			let label = '';
			if (client.is_company) {
				label += client.company_title ? client.company_title + ' ' : '';
			} else {
				label += client.first_name ? client.first_name + ' ' : '';
				label += client.last_name ? client.last_name + ' ' : '';
			}
			return label;
		},
		change_emailInput: function() {
			if (this.templateFetchTimeout !== null) {
				clearTimeout(this.templateFetchTimeout);
			}
			this.templateFetchTimeout = setTimeout(() => {
				this.getTemplate();
			}, 1000);
		}
	},
	data: function () {
		return {
			settings,
			isLoading: false,
			title: '',
			okButton: {
				label: 'Shrani',
				progress: false,
				disabled: false
			},
			cancelButton: {
				label: 'Prekliči',
				progress: false,
				disabled: false
			},
			message: {
				type: null,
				text: null
			},
			ALERT_TYPE_SUCCESS: 'success',
			ALERT_TYPE_ERROR: 'danger',
			mode_UNKNOWN0: 0,
			mode_NORMAL1: 1,
			mode: 0,
			ebill: {
				id: null,
				envelope: null,
				ebillFile: null,
				client: null,
				ebillAttachments: [],
				order: null
			},
			emailSubject: null,
			emailContent: null,
			emailRecievers: null,
			notificationMethod: settings.notificationMethodOptions[0].value,
			sendedEbillEnvolope: null,
			sendedEbillFile: null,
			sendedEbillAttachments: [],
			sendedOrderAttachments: [],
			emailSended: false,
			halcomSended: false,
			showMailContentCard: false,
			sendedEmailAddresses: [],
			invalidEmailAddresses: [],
			templateFetchTimeout: null
		}
	},
	watch: {
	},
	computed: {
		modalSize () {
			return 'lg';
		}
	},
	created () {
	}
}
</script>

<style scoped>
.ma_pos {
	text-align: end;
}
</style>