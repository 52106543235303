<script>

export default {
	name: 'AmountFilter',
	props: {
		value: {
			type: Object,
			required: false
		}
	},
	methods: {
		click_submit (evt) {
			if (this.mode === '') {
				this.$emit('input', null);
				this.$refs.dropdown.hide();
				return;
			}
			if (this.mode === 'single') {
				if (!this.amount) {
					return;
				}
				this.selectedAmount = { value: this.amount };
				this.label = this.amount + ' €';
			} else if (this.mode === 'range') {
				if (!this.amount_from || !this.amount_to) {
					return;
				}
				this.selectedAmount = { min: this.amount_from, max: this.amount_to };
				this.label = this.amount_from + ' - ' + this.amount_to + ' €';
			}
			this.$emit('input', this.selectedAmount);
			this.$refs.dropdown.hide();
		},
		click_clear (evt) {
			this.clearForm();
			this.$emit('input', null);
			this.$refs.dropdown.hide();
		},
		clear () {
			this.clearForm();
		},
		clearForm () {
			this.selectedAmount = null;
			this.label = null;
			this.mode = 'single';
			this.amount = null;
			this.amount_from = null;
			this.amount_to = null;
		},
		change_mode () {
			if (this.mode == 'single') {
				this.amount_from = null;
				this.amount_to = null;
			} if (this.mode == 'range') {
				this.amount = null;
			}
		}
	},
	computed: {
		selectedTitle () {
			if (this.label) {
				return this.label;
			} else {
				return this.defaultItemLabel;
			}
		}
	},
	data: () => {
		return {
			selectedAmount: null,
			label: null,
			mode: 'single',
			amount: null,
			amount_from: null,
			amount_to: null,
			defaultItemLabel: 'Vse'
		}
	}
}
</script>
<template>
	<b-dropdown :text="selectedTitle" ref="dropdown">
		<b-dropdown-header>Znesek</b-dropdown-header>
		<b-dropdown-form class="mt-2 mb-2 amount-dd">
			<b-row align-v="center">
				<b-col>
					<b-form-radio @change="change_mode" v-model="mode" name="mode" value="single">Točna vrednost</b-form-radio>
				</b-col>
				<b-col>
					<currency-input :disabled="mode == 'range'" v-model="amount" placeholder="Znesek" class="form-control" ></currency-input>
				</b-col>
				<b-col></b-col>
			</b-row>
			<b-row align-v="center">
				<b-col>
					<b-form-radio @change="change_mode" v-model="mode" name="mode" value="range">Razpon</b-form-radio>
				</b-col>
				<b-col>
					<currency-input :disabled="mode == 'single'" v-model="amount_from" placeholder="Od" class="form-control" ></currency-input>
				</b-col>
				<b-col>
					<currency-input :disabled="mode == 'single'" v-model="amount_to" placeholder="Do" class="form-control" ></currency-input>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="auto" class="mr-auto"><b-button @click.prevent="click_clear">Počisti</b-button></b-col>
				<b-col cols="auto" ><b-button @click.prevent="click_submit" variant="primary">Potrdi</b-button></b-col>
			</b-row>
		</b-dropdown-form>
	</b-dropdown>
</template>
<style scoped lang="scss">
.amount-dd {
	width: 30rem!important;
	padding-left: 1rem;
	padding-right: 1rem;
	.row {
		margin-top: 14px;
		margin-bottom: 14px;
	}
}
</style>
<style>
</style>