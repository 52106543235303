<template>
	<b-modal :no-close-on-backdrop="true" :hide-header-close="isLoading" ref="modal" :title="title" :size="modalSize" class="modal-primary" @hidden="clearModalData">
		<b-card header="Področje">
			<v-select
				:options="ebillsAreaOptions"
				v-model="ebill.area"
				@input="change_ebillsAreaSelect"
				:searchable="false"
				placeholder="Izberi področje ...">
			</v-select>
		</b-card>
		<b-card header="Stranka">
			<v-select
				:options="allClients"
				v-model="selectedClient"
				@input="change_clientsSelect"
				placeholder="Izberi stranko ..."
				:filter-by="filter_clientsSelect"
				label="id">
				<template slot="no-options">Stranka ne obstaja</template>
				<template slot="option" slot-scope="option">
					{{ getClientSelectLabel(option )}}
				</template>
				<template slot="selected-option" slot-scope="option">
					{{ getClientSelectLabel(option )}}
				</template>
				</v-select>
		</b-card>
		<CAlert v-show="message.text != null" :color="message.type">
			{{ message.text }}
		</CAlert>
		<template slot="modal-footer" slot-scope="{ cancel }">
			<b-container fluid class="p-0">
				<b-row>
					<b-col align-self="end" class="ma_pos">
						<b-button :disabled="isLoading" style="margin-right:5px;" variant="secondary" @click="cancel()">Prekliči</b-button>
						<b-button :disabled="okButton.disabled" variant="primary" @click.stop="onOkModal()">{{ okButton.label }}</b-button>
					</b-col>
				</b-row>
			</b-container>
		</template>
		<vue-loading :active.sync="isLoading" :is-full-page="false"/>
	</b-modal>
</template>

<script>
import settings from '@/settings.js';
import { EBILL_CREATE } from "@/store/actions/ebills";
import { CLIENTS_LIST_ALL } from "@/store/actions/clients";
import PermissionCheck from '@/tools/permission-check';

export default {
	name: 'ebill-create-edit-modal',
	components: {
	},
	props: {
	},
	methods: {
		showModal: function (modalInput) {
			/*
				mode 1 => Create
			*/
			this.mode = modalInput.mode;
			this.initModal(modalInput.ebill);
		},
		initModal: function (ebill) {
			if (this.mode === this.mode_CREATE1) {
				this.title = 'Dodajanje e-računa';
				this.okButton.label = 'Dodaj';
			} else {
				this.mode = this.mode_UNKNOWN0;
				this.title = 'Neznan način';
				this.okButton.label = 'Zapri';
			}
			this.$refs.modal.show();
		},
		initEbill: function () {
			// edit not supported
		},
		onOkModal: function () {
			this.startProgress();
			this.$store.dispatch(EBILL_CREATE, { ebill: this.ebill })
				.then((responseData) => {
					this.stopProgress();
					this.$emit('emit_ebillCreateEdit', responseData);
					this.$refs.modal.hide();
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						if (this.mode === this.mode_CREATE1) {
							this.showAlert('Napaka pri dodajanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else {
							this.showAlert('Neznan način modala! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						}
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		},
		clearModalData: function () {
			this.isLoading = false;
			this.title = '';
			this.okButton = {
				label: 'Shrani',
				progress: false,
				disabled: false
			};
			this.showAlert();
			this.mode = 0;
			this.selectedClient = null;
			this.resetEbillObject();
		},
		resetEbillObject: function () {
			this.ebill.id = null;
			this.ebill.area = null;
			this.ebill.client = null;
		},
		startProgress: function () {
			this.isLoading = true;
			this.okButton.disabled = true;
			this.okButton.progress = true;
		},
		stopProgress: function () {
			this.isLoading = false;
			this.okButton.disabled = false;
			this.okButton.progress = false;
		},
		showAlert: function (message, type, seconds) {
			if (message && type && seconds) {
				this.message.type = type;
				this.message.text = message;
				setTimeout(() => {
					this.showAlert();
				}, seconds * 1000);
			} else if (message && type) {
				this.message.type = type;
				this.message.text = message;
			} else if (message) {
				this.message.type = 'primary';
				this.message.text = message;
			} else {
				this.message.type = null;
				this.message.text = null;
			}
		},
		change_ebillsAreaSelect: function (area) {
			this.ebill.area = area.value;
		},
		change_clientsSelect: function (client) {
			this.ebill.client_id = client.id;
		},
		filter_clientsSelect: (option, label, search) => {
			let s = search.toLowerCase();
			let companyTitle = (option.company_title ? option.company_title.toLowerCase() : '');
			let firstName = (option.first_name ? option.first_name.toLowerCase() : '');
			let lastName = (option.last_name ? option.last_name.toLowerCase() : '');
			let email = (option.email ? option.email.toLowerCase() : '');
			let companyTitleResult = (companyTitle.indexOf(s) > -1);
			let firstNameResult = (firstName.indexOf(s) > -1);
			let lastNameResult = (lastName.indexOf(s) > -1);
			let emailResult = (email.indexOf(s) > -1);
			return companyTitleResult || emailResult || firstNameResult || lastNameResult;
		},
		getClientSelectLabel: function(client) {
			let label = '';
			if (client.is_company) {
				label += client.company_title ? client.company_title + ' ' : '';
				label += client.email ? '(' + client.email + ')' : '';
			} else {
				label += client.first_name ? client.first_name + ' ' : '';
				label += client.last_name ? client.last_name + ' ' : '';
				label += client.email ? '(' + client.email + ')' : '';
			}
			return label;
		},
	},
	data: function () {
		return {
			settings,
			isLoading: false,
			title: '',
			okButton: {
				label: 'Shrani',
				progress: false,
				disabled: false
			},
			message: {
				type: null,
				text: null
			},
			ALERT_TYPE_SUCCESS: 'success',
			ALERT_TYPE_ERROR: 'danger',
			mode_UNKNOWN0: 0,
			mode_CREATE1: 1,
			mode: 0,
			ebill: {
				id: null,
				area: null,
				client: null
			},
			selectedClient: null
		}
	},
	watch: {
	},
	computed: {
		modalSize () {
			return 'md';
		},
		ebillsAreaOptions () {
			let areas = [];
			for(let area of settings.ebillAreaOptions) {
				if (area.value === 'CDP') {
					if (PermissionCheck.can('CDP-write')) {
						areas.push(area);
					}
				} else if (area.value === 'Toyota') {
					if (PermissionCheck.can('Toyota-write')) {
						areas.push(area);
					}
				} else if (area.value === 'Form.NET') {
					if (PermissionCheck.can('Form.NET-write')) {
						areas.push(area);
					}
				} else if (area.value === 'Vasco') {
					if (PermissionCheck.can('Vasco-write')) {
						areas.push(area);
					}
				}
			}
			return areas;
		},
		allClients: function() {
			if (this.$store.getters.getAllClients) {
				return this.$store.getters.getAllClients;
			} else {
				this.$store.dispatch(CLIENTS_LIST_ALL)
					.then((responseData) => {
						console.log(responseData);
						return responseData.clients;
					})
					.catch(error => {
						console.error(error);
					});
			}
		}
	},
	created () {
	}
}
</script>

<style scoped>
.ma_pos {
	text-align: end;
}
</style>