<template>
	<div class="animated fadeIn">
		<b-card>
			<vue-loading :active.sync="ebillsTableProps.isLoading" :is-full-page="false"/>
			<vue-loading :active.sync="isLoadingArchiving" :is-full-page="false"/>
			<vue-loading :active.sync="isLoadingUnarchiving" :is-full-page="false"/>
			<vue-loading :active.sync="isLoadingDeleting" :is-full-page="false"/>
			<div slot="header">
				<b>Seznam e-računov</b>
				<div class="card-header-actions">
					<CInputCheckbox :checked.sync="ebillsTableProps.inputFilter.showArchived" @change="change_showArchived"  label="Prikaži arhivirane" inline/>
					<CLink title="Osveži tabelo" href="#" class="card-header-action btn-setting" @click.stop="click_ebillsTableRefresh">
						<CIcon name="cil-loop-circular" class="mb-1"/>
					</CLink>
					<CLink title="Ponastavi filtre" href="#" class="card-header-action btn-setting" @click.stop="click_ebillsTableResetFilters">
						<CIcon name="cil-x" class="mb-1"/>
					</CLink>
					<CLink title="Dodaj e-račun" href="#" class="card-header-action btn-setting" @click.stop="click_ebillCreate">
						<CIcon name="cil-plus" class="mb-1"/>
					</CLink>
				</div>
			</div>
			<b-table
				ref="ebillsTable"
				responsive="sm"
				striped
				show-empty
				hover
				selectable
				:empty-text="ebillsTableProps.empty_text"
				:empty-filtered-text="ebillsTableProps.empty_filtered_text"
				:fields="ebillsTableProps.fields"
				:current-page="ebillsTableProps.currentPage"
				:per-page="ebillsTableProps.itemsPerPage"
				:items="ebillsTable_items"
				:sort-by.sync="ebillsTableProps.sortBy"
				:sort-desc.sync="ebillsTableProps.sortDesc"
				@row-clicked="rowClicked">
				<template slot="top-row">
						<td></td>
						<td><b-form-input v-model="ebillsTableProps.inputFilter.envelopeDocId" type="text" @input="ebillsTableTextFilter_change"/></td>
						<td><amount-filter ref="amountFilter" v-model="ebillsTableProps.inputFilter.ebillAmountToBePaid" @input="ebillsTableFilter_change"/></td>
						<td><b-form-input v-model="ebillsTableProps.inputFilter.envelopePurpose" type="text" @input="ebillsTableTextFilter_change"/></td>
						<td><b-form-input v-model="ebillsTableProps.inputFilter.ebillNumber" type="text" @input="ebillsTableTextFilter_change"/></td>
						<td><b-form-input v-model="ebillsTableProps.inputFilter.clientTitle" type="text" @input="ebillsTableTextFilter_change"/></td>
						<td><ebill-status-filter ref="ebillStatusFilter" @change="change_ebillStatusFilter"/></td>
						<td></td>
						<td><ebill-area-filter ref="ebillAreaFilter" @change="change_ebillAreaFilter"/></td>
						<td></td>
				</template>
				<template v-slot:cell(created_at)="data">
					{{ data.item.created_at | viewDate }}
				</template>
				<template v-slot:cell(envelope_doc_id)="data">
					<div v-if="data.item.envelope != null">{{ data.item.envelope.doc_id }}</div>
				</template>
				<template v-slot:cell(ebill_number)="data">
					<div v-if="data.item.ebill_file != null">{{ data.item.ebill_file.ebill_number }}</div>
				</template>
				<template v-slot:cell(status)="data" >
					<b-badge :title="ebillStatus(data).title"  :variant="ebillStatus(data).variant" size="sm">
						{{ ebillStatus(data).label }}
					</b-badge>
				</template>
				<template v-slot:cell(ebillAmountToBePaid)="data">
					<div v-if="data.item.ebill_file && data.item.ebill_file.amount_to_be_paid">{{ data.item.ebill_file.amount_to_be_paid | hr_money }}
					</div>
					<div v-else> / </div>
				</template>
				<template v-slot:cell(envelope_purpose)="data">
					<div v-if="data.item.envelope">{{ data.item.envelope.purpose}} 
					</div>
					<div v-else> / </div>
				</template>
				<template v-slot:cell(client)="data">
					<div v-if="data.item.client != null">{{ data.item.client.company_title }}</div>
					<div v-else>/</div>
				</template>
				<template v-slot:cell(actions)="data">
					<div>
						<b-button v-if="false" title="Prikaži e-račun" variant="info" @click.stop="click_ebillShowDetails(data.item)" size="sm" class="mr-2">
							<CIcon name="cil-zoom-in"/>
						</b-button>
						<b-button v-if="permissionCheck.can('ebills-actions') && data.item.archived_at == null" title="Izbriši e-račun" variant="danger" @click.stop="click_ebillDelete(data.item)" size="sm" class="mr-2">
							<CIcon name="cil-trash"/>
						</b-button>
						<b-button v-if="permissionCheck.can('ebills-actions') && data.item.archived_at == null" title="Arhiviraj e-račun" variant="warning" @click.stop="click_ebillArchive(data.item)" size="sm" class="mr-2">
							<CIcon name="cil-briefcase"/>
						</b-button>
						<b-button v-if="permissionCheck.can('ebills-actions') && data.item.archived_at != null" title="Prenesi iz arhiva" variant="warning" @click.stop="click_ebillUnarchive(data.item)" size="sm" class="mr-2">
							<CIcon name="cil-recycle"/>
						</b-button>
						<b-button v-if="data.item.archived_at == null && (permissionCheck.is('admin') || data.item.prepared_time == null)" title="Dodaj priloge" variant="info" @click.stop="click_ebillAddAttachmens(data.item)" size="sm" class="mr-2">
							<CIcon name="cil-cloud-upload"/>
						</b-button>
						<b-button v-if="permissionCheck.can('ebills-actions') && (ebillStatus(data).label == 'Pripravljen' || ebillStatus(data).label == 'Ponovno pripravljen' || ebillStatus(data).label == 'Poslan')" title="Pošlji e-račun" variant="primary" @click.stop="click_ebillSend(data.item)" size="sm" class="mr-2">
							<CIcon name="cil-send"/>
						</b-button>
					</div>
				</template>
				<template slot="row-details" slot-scope="data">
					<ebill-details-card ref="ebillDetailsCard" @emit_ebillPrepared="emit_ebillPrepared" @emit_ebillRejected="emit_ebillRejected" @emit_ebillClientEdited="emit_ebillClientEdited" v-bind:ebill="data.item"/>
				</template>
			</b-table>
			<nav>
				<b-row>
					<b-col>
						<b-pagination :total-rows="ebillsTableProps.totalItems" :per-page="ebillsTableProps.itemsPerPage" v-model="ebillsTableProps.currentPage" prev-text="Prejšnja" next-text="Naslednja" hide-goto-end-buttons />
					</b-col>
					
					<b-col cols=2>
						<a class="page-link rows_total float-right">Št. vrstic: {{ ebillsTableProps.totalItems }}</a>
					</b-col>
					<b-col cols=1>
						<b-form-select v-model="ebillsTableProps.itemsPerPage" :options="ebillsTableProps.options_numRowsOnPage"></b-form-select>
					</b-col>
				</b-row>
			</nav>
		</b-card>
		<vue-toastr ref="toastr"></vue-toastr>
		<ebill-send-modal ref="ebillsendModal" @emit_ebillSend="emit_ebillSend"/>
		<ebill-create-edit-modal ref="ebillCreateEditModal" @emit_ebillCreateEdit="emit_ebillCreateEdit"/>
		<upload-files-modal ref="uploadFilesModal" @emit_filesUploaded="emit_filesUploaded"/>
	</div>
</template>

<script>
import PermissionCheck from '@/tools/permission-check';
import settings from '@/settings.js';
import EbillSendModal from '@/views/modals/EbillSend';
import EbillDetailsCard from '@/views/components/EbillDetailsCard';
import EbillAreaFilter from '@/views/components/DropdownFilters/EbillAreaFilter';
import EbillStatusFilter from '@/views/components/DropdownFilters/EbillStatusFilter';
import AmountFilter from '@/views/components/DropdownFilters/AmountFilter';
import EbillCreateEditModal from '@/views/modals/EbillCreateEdit';
import UploadFilesModal from '@/views/modals/UploadFiles';

import { EBILLS_LIST_FILTERED, EBILL_ARCHIVE, EBILL_UNARCHIVE, EBILL_DELETE } from "@/store/actions/ebills";

export default {
    name: 'Eracuni',
    components: {
		'ebill-send-modal': EbillSendModal,
		'ebill-details-card': EbillDetailsCard,
		'ebill-area-filter': EbillAreaFilter,
		'ebill-status-filter': EbillStatusFilter,
		'ebill-create-edit-modal': EbillCreateEditModal,
		'upload-files-modal': UploadFilesModal,
		'amount-filter': AmountFilter
    },
	data () {
		return {
			ebillsTableProps: {
				sortBy: 'created_at',
				sortDesc: true,
				fields: [
					{ key: 'id', label: 'Id', sortable: true, class: 'tdEbillsId' },
					{ key: 'envelope_doc_id', label: 'Id dokumenta', sortable: false, class: 'tdEbillsEnvelopeDocId' },
					{ key: 'ebillAmountToBePaid', label: 'Znesek', sortable: false, class: 'tdEbillsAmount' },
					{ key: 'envelope_purpose', label: 'Koda namena', sortable: false, class: 'tdEbillsEnvelopePurpose' },
					{ key: 'ebill_number', label: 'Št. računa', sortable: false, class: 'tdEbillsEbillNumber' },
					{ key: 'client', label: 'Stranka', sortable: false, class: 'tdEbillsClient' },
					{ key: 'status', label: 'Status', sortable: false, class: 'tdEbillsNotificationStatus' },
					{ key: 'created_at', label: 'Dodan dne', sortable: true, class: 'tdEbillsCreatedAt' },
					{ key: 'area', label: 'Področje', sortable: true, class: 'tdEbillsArea' },
					{ key: 'actions', label: '#', sortable: false, class: 'tdEbillsActions text-right' },
				],
				currentPage: 1,
				itemsPerPage: 50,
				totalItems: 0,
				isLoading: false,
				options_numRowsOnPage: [
					{ value: 50, text: '50' },
					{ value: 100, text: '100' },
					{ value: 200, text: '200' },
					{ value: 1000, text: '1000' }
				],
				empty_text: '',
				empty_filtered_text: 'Ni zadetkov',
				inputFilter: {
					envelopeDocId: null,
					ebillNumber: null,
					envelopePurpose: null,
					clientTitle: null,
					ebillAmountToBePaid: null,
					area: [],
					status: [],
					showArchived: false
				},
				textFilterTimeout: null,
				errorText: ''
			},
			isLoadingArchiving: false,
			successfulPreparedEbillId: null,
			successfulRejectedEbillId: null,
			successfulUploadedFilesEbillId: null,
			successfulEditedClientEbillId: null,
			isLoadingUnarchiving: false,
			isLoadingDeleting: false,
			permissionCheck: PermissionCheck
		}
	},
    computed: {
		profile() {
			return this.$store.getters.getProfile
		}
    },
    methods: {
		click_ebillsTableRefresh: function () {
			this.$refs.ebillsTable.refresh();
		},
		click_ebillsTableResetFilters: function () {
			this.ebillsTableProps.inputFilter.clientTitle = null;
			this.ebillsTableProps.inputFilter.ebillNumber = null;
			this.ebillsTableProps.inputFilter.envelopePurpose = null;
			this.ebillsTableProps.inputFilter.envelopeDocId = null;
			this.ebillsTableProps.inputFilter.envelopeAmount = null;
			this.ebillsTableProps.inputFilter.area = [];
			this.ebillsTableProps.inputFilter.status = [];
			this.$refs.ebillAreaFilter.clear();
			this.$refs.ebillStatusFilter.clear();
			this.ebillsTableProps.inputFilter.showArchived = false;
			this.$refs.ebillsTable.refresh();
		},
		ebillsTable_items: function (ctx) {
			this.ebillsTableProps.isLoading = true;
			return this.$store.dispatch(EBILLS_LIST_FILTERED, { ctx: ctx, tableProps: this.ebillsTableProps })
				.then((responseData) => {
					this.ebillsTableProps.isLoading = false;
					this.ebillsTableProps.items = responseData.ebillsPagination.data;
					this.ebillsTableProps.totalItems = responseData.ebillsPagination.total;
					this.ebillsTableProps.empty_text = 'Ni vnosov';
					if (this.successfulPreparedEbillId != null) {
						responseData.ebillsPagination.data.find((item) => {
							if (item.id === this.successfulPreparedEbillId) {
								this.rowClicked(item);
							}
						});
						this.successfulPreparedEbillId = null;
					}
					if (this.successfulRejectedEbillId != null) {
						responseData.ebillsPagination.data.find((item) => {
							if (item.id === this.successfulRejectedEbillId) {
								this.rowClicked(item);
							}
						});
						this.successfulRejectedEbillId = null;
					}
					if (this.successfulUploadedFilesEbillId != null) {
						responseData.ebillsPagination.data.find((item) => {
							if (item.id === this.successfulUploadedFilesEbillId) {
								if (this.$refs.ebillDetailsCard !== undefined) {
									this.rowClicked(item);
									if (item.client != null) {
											this.$refs.ebillDetailsCard.refreshAvailableOrders(item.client.id);
									}
								}
							}
						});
						this.successfulUploadedFilesEbillId = null;
					}
					if (this.successfulEditedClientEbillId != null) {
						responseData.ebillsPagination.data.find((item) => {
							if (item.id === this.successfulEditedClientEbillId) {
								this.rowClicked(item);
							}
						});
						this.successfulEditedClientEbillId = null;
					}
					return responseData.ebillsPagination.data;
				})
				.catch(error => {
					this.ebillsTableProps.isLoading = false;
					this.ebillsTableProps.items = [];
					this.ebillsTableProps.totalItems = 0;
					this.ebillsTableProps.empty_text = 'Napaka pri pridobivanju e-računov';
					console.log(error);
					return [];
				});
		},
		ebillsTableTextFilter_change: function (value) {
			if (this.ebillsTableProps.textFilterTimeout !== null) {
				clearTimeout(this.ebillsTableProps.textFilterTimeout);
			}
			this.ebillsTableProps.textFilterTimeout = setTimeout(() => {
				this.$refs.ebillsTable.refresh();
			}, settings.filterTimeoutLength);
		},
		ebillsTableFilter_change: function (value) {
			this.$refs.ebillsTable.refresh();
		},
		click_ebillSend: function (data) {
			let modalInput = {
				mode: 1, // normal mode
				ebill: data
			}
			this.$refs.ebillsendModal.showModal(modalInput);
		},
		click_ebillArchive: function (data) {
			this.$bvModal.msgBoxConfirm('Želite arhivirati e-račun #' + data.id + '?', {
				title: 'Potrdite arhiviranje e-računa',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					console.log('tukaj -1')
					this.isLoadingArchiving = true;
					this.$store.dispatch(EBILL_ARCHIVE, { id: data.id })
						.then((responseData) => {
							this.isLoadingArchiving = false;
							this.$refs.toastr.s('E-račun #' + data.id + ' uspešno arhiviran.');
							this.$refs.ebillsTable.refresh();
						})
						.catch(error => {
							this.isLoadingArchiving = false;
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.$refs.toastr.e('Napaka pri arhiviranju! (' + error.response.data.code + ')');
							} else {
								this.$refs.toastr.e('Napaka pri arhiviranju! Prosimo poizkusite kasneje.');
							}
						}); 
				}
			}).catch(err => {
				console.error(err);
			})
		},
		click_ebillShowDetails: function (data) {
			let routeData = this.$router.resolve({
				path: '/envelope_xml_preview', 
				query: {
					ebillId: data.id
				}
			});
			window.open(routeData.href, '_blank'); 
		},
		emit_ebillSend: function (data) {
			this.$refs.ebillsTable.refresh();
			console.log('Ebil send emit', data);
		},
		emit_ebillPrepared: function (preparedEbillId) {
			this.successfulPreparedEbillId = preparedEbillId;
			this.$refs.ebillsTable.refresh();
		},
		emit_ebillRejected: function (rejectedEbillId) {
			this.successfulRejectedEbillId = rejectedEbillId;
			this.$refs.ebillsTable.refresh();
		},
		rowClicked (row) {
			row._showDetails = !row._showDetails;
		},
		ebillStatus: function (data) {
			if (data.item.prepared_time == null && data.item.prepared_user != null) {
				return {
					variant: 'danger',
					title: 'E-račun je zavrnil ' + data.item.prepared_user.name,
					label: 'Zavrnjen'
				}
			}
			else if (data.item.last_action == null && data.item.prepared_time == null) {
				return {
					variant: 'danger',
					title: 'E-račun še ni bil pripravljen.',
					label: 'V pripravi'
				}
			} else if (data.item.last_action == null && data.item.prepared_time != null) {
				return {
					variant: 'warning',
					title: 'E-račun je pripravil ' + data.item.prepared_user.name + ' dne ' + this.$options.filters.viewDate(data.item.prepared_time),
					label: 'Pripravljen'
				}
			} else if (data.item.last_action != null && data.item.prepared_time != null && data.item.archived_at === null && new Date(data.item.prepared_time) > new Date(data.item.last_action.commited_at)) {
				let title = 'E-račun je ponovno pripravil ' + data.item.prepared_user.name + ' dne ' + this.$options.filters.viewDate(data.item.prepared_time) + '\nPredhodno je bilo poslano obvestilo tipa ' + data.item.last_action.type + ' dne ' + this.$options.filters.viewDate(data.item.last_action.commited_at)
				return {
					variant: 'warning',
					title: title,
					label: 'Ponovno pripravljen'
				}
			}
			else {
				return {
					variant: 'success',
					title: 'Obvestilo tipa ' + data.item.last_action.type + ' poslal ' + data.item.last_action.user_added.name + ' dne ' + this.$options.filters.viewDate(data.item.last_action.commited_at),
					label: 'Poslan'
				}
			}
		},
		change_ebillAreaFilter: function (selectedItems) {
			if (selectedItems && selectedItems.length > 0) {
				this.ebillsTableProps.inputFilter.area = [];
				for (let item of selectedItems) {
					this.ebillsTableProps.inputFilter.area.push(item.value);
				}
			} else {
				this.ebillsTableProps.inputFilter.area = null;
			}
			this.$refs.ebillsTable.refresh();
		},
		change_ebillStatusFilter: function (selectedItems) {
			if (selectedItems && selectedItems.length > 0) {
				this.ebillsTableProps.inputFilter.status = [];
				for (let item of selectedItems) {
					this.ebillsTableProps.inputFilter.status.push(item.value);
				}
			} else {
				this.ebillsTableProps.inputFilter.status = null;
			}
			this.$refs.ebillsTable.refresh();
		},
		change_showArchived: function () {
			this.$refs.ebillsTable.refresh();
		},
		click_ebillCreate: function () {
			let modalInput = {
				mode: 1, // normal mode
				ebill: null
			}
			this.$refs.ebillCreateEditModal.showModal(modalInput);
		},
		emit_ebillCreateEdit: function (data) {
			this.$refs.ebillsTable.refresh();
			console.log('Ebill added emit', data);
		},
		click_ebillAddAttachmens: function (data) {
			let modalInput = {
				mode: 1, // ebill attachmend upload mode
				ebill: data
			}
			this.$refs.uploadFilesModal.showModal(modalInput);
		},
		emit_filesUploaded: function (uploadedFilesEbillId) {
			this.successfulUploadedFilesEbillId = uploadedFilesEbillId;
			this.$refs.ebillsTable.refresh();
		},
		emit_ebillClientEdited: function (editedClientEbillId) {
			this.successfulEditedClientEbillId = editedClientEbillId;
			this.$refs.ebillsTable.refresh();
		},
		click_ebillUnarchive: function (data) {
			this.$bvModal.msgBoxConfirm('Želite prenesti e-račun #' + data.id + ' iz arhiva?', {
				title: 'Potrdite prenos računa iz arhiva',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.isLoadingUnarchiving = true;
					this.$store.dispatch(EBILL_UNARCHIVE, { id: data.id })
						.then((responseData) => {
							this.isLoadingUnarchiving = false;
							this.$refs.toastr.s('E-račun #' + data.id + ' uspešno prenesen iz arhiva.');
							this.$refs.ebillsTable.refresh();
						})
						.catch(error => {
							this.isLoadingUnarchiving = false;
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.$refs.toastr.e('Napaka pri prenosu iz arhiva! (' + error.response.data.code + ')');
							} else {
								this.$refs.toastr.e('Napaka pri prenosu iz arhiva! Prosimo poizkusite kasneje.');
							}
						}); 
				}
			}).catch(err => {
				console.error(err);
			})
		},
		click_ebillDelete: function (data) {
			this.$bvModal.msgBoxConfirm('Želite izbrisati e-račun #' + data.id + '?', {
				title: 'Potrdite brisanje e-računa',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.isLoadingDeleting = true;
					this.$store.dispatch(EBILL_DELETE, { id: data.id })
						.then((responseData) => {
							this.isLoadingDeleting = false;
							this.$refs.toastr.s('E-račun #' + data.id + ' uspešno izbrisan.');
							this.$refs.ebillsTable.refresh();
						})
						.catch(error => {
							this.isLoadingDeleting = false;
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.$refs.toastr.e('Napaka pri brisanju e-računa! (' + error.response.data.code + ')');
							} else {
								this.$refs.toastr.e('Napaka pri brisanju e-računa! Prosimo poizkusite kasneje.');
							}
						}); 
				}
			}).catch(err => {
				console.error(err);
			})
		},
    },
    created: function () {
    }
  
}
</script>
<style scoped>
.table-responsive-sm {
	margin-bottom: 0;
}
.pagination {
	margin-left: 1rem;
	margin-right: 1rem;
}
.page-link.rows_total {
	text-align: center;
	border-radius: 5px;
	width: 120px;
}
</style>
<style>
.tdEbillsId {
	width: 60px;
}
.tdEbillsEnvelopeDocId {
	width: 100px;
}
.tdEbillsAmount {
	width: 7rem;
}
.tdEbillsEnvelopePurpose {
	width: 130px;
}
.tdEbillsEbillNumber {
	width: 100px;
}
.tdEbillsNotificationStatus {
	width: 100px;
}
.tdEbillsCreatedAt {
	width: 200px;
}
.tdEbillsArea {
	width: 100px;
}
.tdEbillsActions {
	width: 240px;
}
</style>